import React, { useState } from 'react';
import '../assets/style/error.css';
import { Link } from 'react-router-dom';
import notFoundImage from '../assets/images/404.svg';
import logo from "../assets/images/logo-icon.png";
const Error = () => {
  const [transform, setTransform] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const mouseX = e.clientX - left;
    const mouseY = e.clientY - top;
    const moveX = ((mouseX / width) - 0.5) * 20; // Adjust 20 to control the movement range
    const moveY = ((mouseY / height) - 0.5) * 20; // Adjust 20 to control the movement range

    setTransform({ x: moveX, y: moveY });
  };

  const handleMouseLeave = () => {
    setTransform({ x: 0, y: 0 });
  };
  return (
    <div className='vh-100 bg' onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
      <div className="container error-container">
        <img src={logo} alt="Neosao Logo" style={{ width: "40px" }} />
      </div>
      <div className='d-flex flex-column align-items-center justify-content-center '>
        <div className='relative'>
          <img className='img404' src={notFoundImage} alt="404" style={{ transform: `translate(${transform.x}px, ${transform.y}px)` }} />
          <div class="err404 digit-container">
            <span class="digit bounce">4</span>
            <span class="digit flip-bounce zero">0</span>
            <span class="digit bounce ">4</span>
          </div>
        </div>
        <div className='text-center relative mb-2'>
          <div className='text-danger mb-1'>Hmm... It looks like you got lost</div>
          <div className='text-light mb-1'>Please Go to the home page and check it out</div>
        </div>
        <div className='relative'>
          <Link to={'/'} type='button' className='go-back'>Go back</Link>
        </div>
      </div>
    </div>
  )
}

export default Error